import React from "react"
import { Link } from "gatsby"
import Logo from "./logo"

// Material UI
import Grid from "@material-ui/core/Grid"

// Styles
import { colors, mediaQueries } from "../assets"
import styled from "styled-components"

const Footer = ({ urls, docs }) => (
  <FooterContainer container>
    <Grid item xs={12} md={3}>
      <LogoLink
        to={urls.home.href}
        title={urls.home.title}
      >
        <Logo />
      </LogoLink>
    </Grid>
    <FooterLinks item xs={12} md={9}>
      {Object.keys(urls).map(key =>
        urls[key].title !== 'Home' &&
          <Link key={key} to={urls[key].href}>{urls[key].title}</Link>
      )}
      {Object.keys(docs).map(key => 
        <Link key={key} to={docs[key].href}>{docs[key].title}</Link>
      )}
    </FooterLinks>
    <Legal item xs={12}>
      <p>© {new Date().getFullYear()} Tetra Trust Company</p>
    </Legal>
  </FooterContainer>
)

const FooterContainer = styled(Grid)`
  background: ${ colors.TETRA_BLACK };
  padding: 20px 25px;

  ${ mediaQueries.MD_UP } {
    padding: 25px 75px;
  }
`

const LogoLink = styled(Link)`
  display: inline-block;
  width: 130px;
  margin: 0 0 25px;

  ${ mediaQueries.MD_UP } {
    margin: 0;
  }
`

const FooterLinks = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  ${ mediaQueries.MD_UP } {
    justify-content: flex-end;
    flex-wrap: nowrap;
  }

  a {
    display: flex;
    width: 50%;
    height: 34px;
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: ${ colors.TETRA_WHITE };

    ${ mediaQueries.SM_UP } {
      width: 33%;
      height: auto;
    }

    ${ mediaQueries.MD_UP } {
      width: auto;
      margin: 0 0 0 20px;
      font-size: 14px;
    }

    ${ mediaQueries.LG_UP } {
      font-size: 16px;
      margin: 0 0 0 45px;
    }
  }
`

const Legal = styled(Grid)`
  padding-top: 10px;

  ${ mediaQueries.MD_UP } {
    padding-top: 5px;
  }

  p {
    margin: 0;
    font-size: 14px !important;
    color: ${ colors.TETRA_WHITE };
  }
`

export default Footer
