import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "./logo"

// Material UI
import Grid from "@material-ui/core/Grid"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/MenuRounded"
import CloseIcon from "@material-ui/icons/CloseRounded"

// Styles
import { colors, fonts, mediaQueries } from "../assets"
import styled from "styled-components"

const Header = props => {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const ListItemLink = props => {
    return <ListItem button component={Link} {...props} />
  }

  const { urls } = props

  return (
    <NavBar container>
      <HamburgerContainer item xs={2}>
        <MenuButton
          onClick={handleDrawerToggle}
          aria-label="Menu">
          {mobileOpen
            ? <MenuIconClose />
            : <MenuIconOpen />
          }
        </MenuButton>
      </HamburgerContainer>

      <MobileDrawer
        anchor="left"
        open={mobileOpen}
        transitionDuration={200}
        onClose={handleDrawerToggle}>
        <div>
          <MobileList>
            {Object.keys(urls).map(key => 
              urls[key].title !== 'Home' &&
                <ListItemLink key={key} to={urls[key].href}>
                  <ListItemText primary={urls[key].title} />
                </ListItemLink>
            )}
          </MobileList>
        </div>
      </MobileDrawer>

      <NavLogo item xs={8} md={4}>
        <LogoLink
          to={urls.home.href}
          title={urls.home.title}
        >
          <Logo />
        </LogoLink>
      </NavLogo>

      <DesktopMenu item md={8}>
        {Object.keys(urls).map(key =>
          urls[key].title !== 'Home' &&
            <Link key={key} to={urls[key].href}>{urls[key].title}</Link>
        )}
      </DesktopMenu>
    </NavBar>
  )
}

const NavBar = styled(Grid)`
  height: 48px;
  margin: 0 auto;
  padding: 8px 15px;
  background: ${ colors.TETRA_BLACK };

  ${ mediaQueries.MD_UP } {
    justify-content: flex-start;
    max-width: 960px;
    height: 88px;
    padding: 15px 80px;
  }

  ${ mediaQueries.MD_UP } {
    max-width: 1280px;
  }

  ${ mediaQueries.LG_UP } {
    max-width: 1440px;
  }
`

const HamburgerContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${ mediaQueries.MD_UP } {
    display: none;
  }
`

const MenuButton = styled(IconButton)`
  padding: 0;
`

const MenuIconOpen = styled(MenuIcon)`
  color: ${ colors.TETRA_WHITE };
`

const MenuIconClose = styled(CloseIcon)`
  color: ${ colors.TETRA_WHITE };
`

const NavLogo = styled(Grid)`
  text-align: center;

  ${ mediaQueries.MD_UP } {
    text-align: left;
  }
`

const MobileDrawer = styled(Drawer)`
  top: 48px !important;

  > div {
    top: 48px;
  }

  > div.MuiDrawer-paper {
    width: 100%;
    max-width: 90%;
    background: ${ colors.TETRA_BLACK };
  }

  .MuiPaper-elevation16 {
    box-shadow: none;
  }
`

const MobileList = styled(List)`
  a {
    padding: 0 20px;
    color: ${ colors.TETRA_WHITE };

    span {
      font-family: ${ fonts.LATO };
      font-size: 14px;
      line-height: 32px;
      text-transform: uppercase;
    }
  }
`

const LogoLink = styled(Link)`
  display: inline-block;
  width: 111px;

  ${ mediaQueries.MD_UP } {
    width: 195px;
  }
  
  img {
    margin: 0;
  }
`

const DesktopMenu = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${ mediaQueries.SM_DOWN } {
    display: none;
  }

  a {
    display: flex;
    margin-left: 48px;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${ colors.TETRA_WHITE };

    &:first-child {
      margin: 0;
    }
  }
`

Header.propTypes = {
  urls: PropTypes.object,
}

export default Header
