const colors = {
  PRIMARY: '#1A191A',
  PRIMARY_LIGHT: '#E7E7E7',
  PRIMARY_MEDIUM: '#767676',
  PRIMARY_DARK: '#000000',

  SECONDARY: '#253AE4',
  SECONDARY_LIGHT: '#6871FF',
  SECONDARY_DARK: '#0619B0',

  NEUTRAL: '#FFFFFF',
  NEUTRAL_LIGHT: '#FAFAFA',
  NEUTRAL_MEDIUM: '#F5F5F5',

  ACCENT_GREEN: '#7ED321',

  ACCENT_YELLOW: '#FFC900',
  ACCENT_YELLOW_LIGHT: '#FFE277',
  ACCENT_YELLOW_DARK: '#F79D00',

  ACCENT_ORANGE: '#F47A06',
  ACCENT_ORANGE_DARK: '#DB6100',

  ACCENT_PURPLE: '#4648E5',
  ACCENT_PURPLE_LIGHT: '#4153E7',
  ACCENT_PURPLE_DARK: '#1B1B6D',

  ACCENT_RED: '#FF5942',
  ACCENT_RED_LIGHT: '#FF8F6B',
  ACCENT_RED_DARK: '#E5210E',

  NAV_LINK_GREY: '#B0B0B0',

  BRAND_XLM: '#31BBE7',

  REFER_GRAPH: {
    COINSQUARE: '#CC22EF',
    AMAZON: '#2DE58E',
    UBER: '#4A90E2',
  },

  TETRA_BLACK: '#000000',
  TETRA_GREY_DARK: '#7B7B7B',
  TETRA_GREY_DARK_SAFE: '#737272',
  TETRA_GREY: '#E6E6E6',
  TETRA_GREY_LIGHT: '#F8F8F8',
  TETRA_BANNER_DARK: '#1F1E20',
  TETRA_BANNER_LIGHT: '#323334',
  TETRA_WHITE: '#FFFFFF',
  TETRA_BLUE: '#002D72',
  TETRA_BLUE_LIGHT: '#0095DE',
  TETRA_RED: '#DA291C',
  TETRA_BLACK_LIGHT: '#333333',
  TETRA_BLACK_LIGHTER: '#444444',
  TETRA_GREY1: '#F4F4F4',
  TETRA_GREY_DARK1: '#D4D4D4',
  TETRA_GREY_LIGHT1: '#666666',
}

export default colors
