import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

// Styles
import { colors, fonts, mediaQueries } from "../assets"
import styled from "styled-components"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          urls {
            home {
              title
              href
            }
            aboutUs {
              title
              href
            }
            technology {
              title
              href
            }
            contact {
              title
              href
            }
          }
          docs {
            privacyPolicy {
              title
              href
            }
            termsOfUse {
              title
              href
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <HeaderContainer>
        <Header urls={data.site.siteMetadata.urls} />
      </HeaderContainer>

      <MainContainer>
        {children}
      </MainContainer>

      <FooterContainer>
        <Footer
          urls={data.site.siteMetadata.urls}
          docs={data.site.siteMetadata.docs}
        />
      </FooterContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  min-height: 100vh;
  background: ${ colors.TETRA_BLACK };
  font-family: ${ fonts.LATO };

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
    font-family: ${ fonts.LATO };
    font-weight: 700;
    color: ${ colors.TETRA_BLACK };
  }

  h1 {
    font-size: 24px;
    line-height: 32px;
    color: ${ colors.TETRA_WHITE };

    ${ mediaQueries.MD_UP } {
      font-size: 56px;
      line-height: 64px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 32px;

    ${ mediaQueries.SM_UP } {
      font-size: 48px;
      line-height: 64px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    ${ mediaQueries.MD_UP } {
      font-size: 24px;
      line-height: 36px;
    }
  }

  h4 {
    font-size: 20px;
    line-height: 24px;

    ${ mediaQueries.SM_UP } {
      font-size: 32px;
      line-height: 40px;
    }
  }

  h5 {
    font-size: 20px;
    line-height: 24px;

    ${ mediaQueries.SM_UP } {
      font-size: 24px;
      line-height: 32px;
    }
  }

  h6 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  ul {
    margin-left: 18px;

    li {
      margin: 0;
      font-size: 16px;
      line-height: 32px;
    }
  }
`

const HeaderContainer = styled.div`
  ${ mediaQueries.CONTAINER_SIZES }
`

const MainContainer = styled.div`
  ${ mediaQueries.CONTAINER_SIZES }
`

const FooterContainer = styled.div`
  > div {
    ${ mediaQueries.CONTAINER_SIZES }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
