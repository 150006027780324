import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      logoImage: file(relativePath: { eq: "tetra-logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 195)
        }
      }
    }
  `)

  return <GatsbyImage
    image={data.logoImage.childImageSharp.gatsbyImageData}
    alt={data.site.siteMetadata.title}
  />
}

export default Logo
