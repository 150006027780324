const mediaQueries = {
  SM_MIN: '600px',
  MD_MIN: '960px',
  LG_MIN: '1280px',

  CONTAINER_SIZES: `
    width: 100%;
    margin: 0 auto;

    @media (min-width: 600px) {
      max-width: 960px;
    }

    @media (min-width: 960px) {
      max-width: 1280px;
    }

    @media (min-width: 1280px) {
      max-width: 1440px;
    }
  `,

  XS_DOWN: '@media (max-width: 319px)',
  XS_UP: '@media  (min-width: 320px)',

  XS_UP_SM_DOWN: '@media (min-width: 320px) and (max-width: 599px)',

  SM_DOWN: '@media (max-width: 599px)',
  SM_UP: '@media (min-width: 600px)',

  SM_UP_MD_DOWN: '@media (min-width: 600px) and (max-width: 959px)',

  MD_DOWN: '@media (max-width: 959px)',
  MD_UP: '@media (min-width: 960px)',

  MD_UP_LG_DOWN: '@media (min-width: 960px) and (max-width: 1279px)',

  LG_DOWN: '@media (max-width: 1279px)',
  LG_UP: '@media (min-width: 1280px)',
}

export default mediaQueries
